const MAX_LINKS = 5;
const FEED_URL = `https://api.allorigins.win/raw?url=${encodeURIComponent('https://reederapp.net/NIZ1dvhfSgWT1x6ym-FTng.json')}`;

// function getCurrentSeason() {
//     const month = new Date().getMonth() + 1;

//     if (month >= 3 && month <= 5) {
//         return "spring";
//     } else if (month >= 6 && month <= 8) {
//         return "summer";
//     } else if (month >= 9 && month <= 11) {
//         return "fall";
//     } else {
//         return "winter";
//     }
// }
// function setSeasonTheme() {
//     const season = getCurrentSeason();
//     document.body.classList.add(`${season}`);
//     if (document.body) {
//         document.body.style.setProperty('--theme-color', `var(--color-${season})`);
//     }
// }

document.addEventListener("DOMContentLoaded", (event) => {
    populateListFromJson(document.querySelector('#links'));
});

async function populateListFromJson(element) {
    try {
        const response = await fetch(FEED_URL);
        const data = await response.json();
        const ul = document.createElement('ul');
        data.items.slice(0,MAX_LINKS).forEach(item => {
            const li = document.createElement('li');
            const a = document.createElement('a');
            a.setAttribute('href', item.url);
            a.setAttribute('rel', 'noreferrer');
            a.textContent = item.title || (item.content_text.substr(0, 30) + "\u2026");
            li.appendChild(a);
            ul.appendChild(li);
        });
        element.appendChild(ul);
    } catch (error) {
        console.error('Error fetching or parsing JSON:', error);
    }
}